@import "../../styles/_chunk";

.about-us {
  background-color: $about-bg-color;
  color: $text-black;
  margin-bottom: -1.5rem;

  p.about-paragraph {
    font-size: 16px;
    text-align: center;
  }

  h2,
  h3,
  h5 {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }

  .about-inverse-section {
    background-color: $about-section-color;
  }

  .section-title {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .about-content {
    p {
      line-height: 1.8rem;
      font-weight: 400;
    }

    @media #{$retina-screen} {
      p {
        font-weight: inherit;
      }
    }

    a {
      color: $primary-core;
    }

    .publisher-box-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }

    .publisher-box {
      width: 160px;
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      position: relative;
      background-color: $about-bg-color;
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        width: 210px;
        height: 100px;
      }

      @include media-breakpoint-up(lg) {
        width: 170px;
        margin-bottom: 1.5rem;
      }

      @include media-breakpoint-up(xl) {
        width: 200px;
        height: 110px;
        margin-bottom: 2rem;
      }

      @include media-breakpoint-up(xxl) {
        width: 240px;
      }

      img.sega-logo {
        width: 80px;

        @include media-breakpoint-up(md) {
          width: 90px;
        }

        @include media-breakpoint-up(lg) {
          width: 80px;
        }

        @include media-breakpoint-up(xxl) {
          width: 100px;
        }
      }

      img.bethesda-logo {
        width: 110px;

        @include media-breakpoint-up(md) {
          width: 130px;
        }

        @include media-breakpoint-up(lg) {
          width: 110px;
        }

        @include media-breakpoint-up(xl) {
          width: 130px;
        }

        @include media-breakpoint-up(xxl) {
          width: 140px;
        }
      }

      img.rockstar-logo {
        width: 45px;

        @include media-breakpoint-up(md) {
          width: 50px;
        }

        @include media-breakpoint-up(lg) {
          width: 45px;
        }

        @include media-breakpoint-up(xl) {
          width: 50px;
        }

        @include media-breakpoint-up(xxl) {
          width: 55px;
        }
      }

      img.wb-logo {
        width: 45px;

        @include media-breakpoint-up(md) {
          width: 50px;
        }

        @include media-breakpoint-up(xl) {
          width: 55px;
        }
      }

      img.bandai-logo {
        width: 75px;

        @include media-breakpoint-up(md) {
          width: 80px;
        }

        @include media-breakpoint-up(lg) {
          width: 70px;
        }

        @include media-breakpoint-up(xl) {
          width: 80px;
        }
      }

      img.two-k-logo {
        width: 60px;

        @include media-breakpoint-up(md) {
          width: 65px;
        }

        @include media-breakpoint-up(lg) {
          width: 55px;
        }

        @include media-breakpoint-up(xl) {
          width: 60px;
        }

        @include media-breakpoint-up(xxl) {
          width: 70px;
        }
      }

      img.capcom-logo {
        width: 100px;

        @include media-breakpoint-up(md) {
          width: 120px;
        }

        @include media-breakpoint-up(lg) {
          width: 100px;
        }

        @include media-breakpoint-up(xl) {
          width: 120px;
        }

        @include media-breakpoint-up(xxl) {
          width: 130px;
        }
      }

      img.ubisoft-logo {
        width: 100px;

        @include media-breakpoint-up(md) {
          width: 110px;
        }

        @include media-breakpoint-up(lg) {
          width: 100px;
        }

        @include media-breakpoint-up(xl) {
          width: 120px;
        }

        @include media-breakpoint-up(xxl) {
          width: 130px;
        }
      }

      img.squareenix-logo {
        width: 110px;

        @include media-breakpoint-up(lg) {
          width: 120px;
        }

        @include media-breakpoint-up(xl) {
          width: 140px;
        }

        @include media-breakpoint-up(xxl) {
          width: 160px;
        }
      }

      img.disney-logo {
        width: 90px;
        margin-top: 6px;

        @include media-breakpoint-up(md) {
          width: 100px;
        }

        @include media-breakpoint-up(lg) {
          width: 90px;
        }

        @include media-breakpoint-up(xl) {
          width: 100px;
        }

        @include media-breakpoint-up(xxl) {
          width: 110px;
        }
      }
    }

    .story-box-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;
    }

    .story-box {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.75rem;
      flex-direction: column;

      p {
        margin: 0;
      }

      @include media-breakpoint-up(sm) {
        width: 120px;
      }

      @include media-breakpoint-up(md) {
        width: 200px;
        padding: 2rem;
      }
    }

    .about-chevron {
      color: $grey-4;
    }

    .why-fanatical {
      background-color: $about-section-color;
      overflow: hidden;

      @include media-breakpoint-up(lg) {
        box-shadow: inset 20px 0 0 20px $about-bg-color;
      }

      .image-left {
        height: 350px;

        &::before {
          content: '';
          position: absolute;
          width: 200%;
          height: 100%;
          right: 0;
          bottom: 0;

          @include media-breakpoint-up(md) {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
        }
      }

      .half-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          text-align: left;
        }
      }
    }

    img.cdrom {
      width: 200px;
    }

    img.trustpilot {
      width: 200px;
    }

    img.ukie {
      width: 100px;
    }

    .integrity {
      position: relative;
      margin-bottom: 4rem;

      @include media-breakpoint-up(md) {
        height: 100%;
        background: url('https://cdn.fanatical.com/production/brand/about-us/skyrim-pan.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-color: $grey-14;
        background-size: 1320px;
        margin-bottom: 4rem;
      }

      @include media-breakpoint-up(lg) {
        height: 300px;
        background-size: 1750px;
        margin-bottom: 10rem;
      }

      @include media-breakpoint-up(xl) {
        background-size: 2200px;
      }

      .integrity-container {
        width: 100%;
        top: 4rem;
        padding: 2rem;

        @include media-breakpoint-up(md) {
          width: 380px;
        }

        @include media-breakpoint-up(lg) {
          position: relative;
          right: 0;
          padding: 3rem;
        }

        p {
          text-align: left;
        }
      }
    }

    .contact-box-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      @include media-breakpoint-up(md) {
        justify-content: space-between;
      }
    }

    .contact-box {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      margin-bottom: 1rem;
      flex-direction: column;

      p {
        margin: 0;
      }

      span {
        width: 100%;
        display: block;
        text-align: center;
      }

      @include media-breakpoint-up(sm) {
        width: 240px;
      }

      @include media-breakpoint-up(md) {
        width: 32%;
        margin-bottom: 2rem;
      }
    }

    .contact-map {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
        height: 400px;
      }

      @include media-breakpoint-up(lg) {
        height: 500px;
      }

      @include media-breakpoint-up(xxl) {
        height: 600px;
      }

      .map {
        width: 50%;
        position: relative;
        overflow: hidden;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }

      .address {
        width: 100%;
        background-color: $about-section-color;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        @include media-breakpoint-up(sm) {
          width: 50%;
          text-align: left;
        }

        p {
          margin: 0;
        }

        b {
          font-weight: 700;
          font-size: 20px;
        }
      }
    }
  }
}
